import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {ButtonElement, CopyToClipboardBlock} from '../elements'
import {setModalConfirmation} from '../../store/appSlice'
import {compactString} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {DBWhitelist} from '../../store/types'
import {ThemeContext} from '../../context'
import {TicketIcon} from '../icons'
import {delTicketFromWhitelist} from '../../store/ticketsSlice'
import {getToken, requestToken} from '../../store/tokensSlice'

interface PropsType {
    item: DBWhitelist
}

const WhitelistItem = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const token = useSelector(getToken(item.contract, item.tokenId))
    const networkStr = `0x${Number(item.network).toString(16)}`

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestToken({address: item.contract, tokenId: item.tokenId, network: networkStr}))
    }, [])

    const deleteHandler = () => {
        dispatch(setModalConfirmation({
            title: t('modal.question.deleteTicketFromWhitelist'),
            confirmAction: () => {
                dispatch(delTicketFromWhitelist({
                    chain: Number(item.network),
                    contract: item.contract,
                    tokenId: item.tokenId,
                    assetType: item.assetType,
                    levelId: item.level.id,
                }))
            },
        }))
    }

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-4 col-md-auto order-1 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[item.network]?.iconSvg}`}
                    alt={CHAINS[item.network]?.label}
                />
            </div>
            <div className="mb-2 col-4 col-md-auto order-3 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.type')}:</span>
                <span className={`icon-${item.address !== '' ? 'ticket' : 'ticket-active'}`}><TicketIcon/></span>
            </div>
            <div className="mb-2 col-8 col-md order-2 order-md-0 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-8 col-md col-xl-2 order-4 order-md-0">
                {token ?
                    <div className="row-table__item-img"><img src={token.image} alt=""/></div>
                    :
                    null
                }
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="mb-2 col-md order-5 order-md-0">
                <span>{item.level ? `${item.level.title} (${item.level.level})` : ''}</span>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <ButtonElement
                    small
                    outline
                    onClick={deleteHandler}
                >{t('button.delete')}</ButtonElement>
            </div>
        </div>
        <div className="row-table__item-toggle d-lg-none">
            <ButtonElement
                small
                onClick={deleteHandler}
            >{t('button.delete')}</ButtonElement>
        </div>
    </div>
}

export default WhitelistItem
