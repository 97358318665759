import ReactGA from 'react-ga4'

type EventAction = 'ClickAIButton' | 'ClickDailyTemplate' | 'ConnectWallet' | 'GiftsMinted' | 'MintGifts'
type EventCategory = 'Default' | 'Gifts'

namespace GATracker {
    export const sendPageTracker = (page: string = '/', title: string = 'Default title') => {
        ReactGA.send({hitType: 'pageview', page, title})
    }

    export const sendEventTracker = (
        category: EventCategory,
        action: EventAction,
        label?: string
    ) => {
        ReactGA.event({category, action, label})
    }
}

export default GATracker
