import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {ThemeContext} from '../../context'
import {ENVIRONMENT} from '../../utils/constants'

const ToggleDarkMode = () => {
    const {t} = useTranslation()
    const {dark, toggleDark} = useContext(ThemeContext)

    if (ENVIRONMENT === 'prod') {
        return null
    }

    return <button className="btn p-1 btn-link btn-with-opacity me-3" onClick={toggleDark}>
        <img
            src={`/static/img/icons/${dark ? 'sun.svg' : 'moon.svg'}`}
            alt={t('element.switchMode', {mode: dark ? t('element.lightMode') : t('element.darkMode')})}
        />
    </button>
}

export default ToggleDarkMode
