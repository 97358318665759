import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'
import {RootState} from './store'
import {initInputData, InputData, IProperty, ITicketPrice} from './types'
import {createIpfsLink} from '../utils/functions'

interface InputState {
    batch: InputData<number | null>
    collaterals: InputData<ITicketPrice[]>
    dateFilter: Date | null
    dateFilterAsc: boolean
    description: InputData<string>
    externalUrl: string
    imageUrl: InputData<string | null>
    loadedImage: File | null
    properties: InputData<IProperty[]>
    recipients: InputData<string[]>
    title: InputData<string>
    unlockTime: InputData<number | null>
}
interface LoadingExternalUrlType {
    error?: string
    file?: File
}

const initialState: InputState = {
    batch: initInputData(1),
    collaterals: initInputData([]),
    dateFilter: null,
    dateFilterAsc: false,
    description: initInputData(''),
    externalUrl: '',
    imageUrl: initInputData(null),
    loadedImage: null,
    properties: initInputData([]),
    recipients: initInputData([]),
    title: initInputData(''),
    unlockTime: initInputData(null)
}

const revokeImage = (image: string) => {
    if (image) {
        URL.revokeObjectURL(image)
    }
}

export const loadExternalUrl = createAsyncThunk(
    'input/loadExternalUrl',
    async (url: string): Promise<LoadingExternalUrlType | null> => {
        if (url === '') {
            return null
        }
        const res = await fetch(createIpfsLink(url), {mode: 'no-cors'})
        if (res.status === 200 || res.status === 0) {
            const contentType = res.headers.get('Content-Type') || undefined
            const data = await res.arrayBuffer()
            const blob = new Blob([data])
            const file = new File([blob], 'filename', {type: contentType})
            return {file}
        } else {
            console.log(`Loading error with status ${res.status}`)
            return {error: res.statusText}
        }
    }
)

export const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        addCollateral: (state, action: PayloadAction<ITicketPrice>) => {
            let newState: ITicketPrice[] = [...state.collaterals.value]
            let found = false
            for (let key in newState) {
                const item = newState[key]
                if (item.token === action.payload.token && item.customContract === action.payload.customContract) {
                    const newAmount = ethers.utils.parseUnits(item.price, item.decimals).add(ethers.utils.parseUnits(action.payload.price, item.decimals))
                    newState[key].price = ethers.utils.formatUnits(newAmount, item.decimals)
                    found = true
                    break
                }
            }
            if (!found) {
                newState.push(action.payload)
            }
            state.collaterals.value = newState
        },
        addRecipient: (state, action: PayloadAction<string>) => {
            let newState: string[] = [...state.recipients.value]
            newState.push(action.payload)
            state.recipients.value = newState
        },
        delCollateral: (state, action: PayloadAction<number>) => {
            let newState: ITicketPrice[] = []
            for (let i = 0; i < state.collaterals.value.length; i++) {
                if (i !== action.payload) {
                    newState.push(state.collaterals.value[i])
                }
            }
            state.collaterals.value = newState
        },
        delRecipient: (state, action: PayloadAction<number>) => {
            let newState: string[] = []
            for (let i = 0; i < state.recipients.value.length; i++) {
                if (i !== action.payload) {
                    newState.push(state.recipients.value[i])
                }
            }
            state.recipients.value = newState
        },
        editCollateral: (state, action: PayloadAction<{ index: number, value: ITicketPrice }>) => {
            let newState: ITicketPrice[] = [...state.collaterals.value]
            newState[action.payload.index] = {...action.payload.value}
            state.collaterals.value = newState
        },
        resetImage: (state) => {
            state.externalUrl = ''
            state.imageUrl = initInputData(null)
            state.loadedImage = null
        },
        resetState: (state) => {
            let key: keyof InputState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setBatch: (state, action: PayloadAction<number | null>) => {
            state.batch.value = action.payload
        },
        setBatchError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.batch.error = {status: true, text: action.payload}
            } else {
                state.batch.error = {status: false, text: ''}
            }
        },
        setCollaterals: (state, action: PayloadAction<ITicketPrice[]>) => {
            state.collaterals.value = action.payload
        },
        setCollateralsError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.collaterals.error = {status: true, text: action.payload}
            } else {
                state.collaterals.error = {status: false, text: ''}
            }
        },
        setDateFilter: (state, action: PayloadAction<Date | null>) => {
            state.dateFilter = action.payload
        },
        setDateFilterAsc: (state, action: PayloadAction<boolean>) => {
            state.dateFilterAsc = action.payload
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.description.value = action.payload
        },
        setDescriptionError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.description.error = {status: true, text: action.payload}
            } else {
                state.description.error = {status: false, text: ''}
            }
        },
        setExternalUrl: (state, action: PayloadAction<string>) => {
            if (action.payload !== '') {
                if (state.imageUrl.value) {
                    revokeImage(state.imageUrl.value)
                }
                state.imageUrl.value = null
                state.loadedImage = null
            }
            state.externalUrl = action.payload
        },
        setImageUrlError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.imageUrl.error = {status: true, text: action.payload}
            } else {
                state.imageUrl.error = {status: false, text: ''}
            }
        },
        setLoadedImage: (state, action: PayloadAction<File | null>) => {
            state.loadedImage = action.payload
            if (!action.payload) {
                if (state.imageUrl.value) {
                    revokeImage(state.imageUrl.value)
                }
                state.imageUrl.value = null
            } else {
                state.imageUrl.value = URL.createObjectURL(action.payload)
            }
            state.externalUrl = ''
        },
        setProperties: (state, action: PayloadAction<IProperty[]>) => {
            state.properties.value = action.payload
        },
        setPropertiesError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.properties.error = {status: true, text: action.payload}
            } else {
                state.properties.error = {status: false, text: ''}
            }
        },
        setRecipients: (state, action: PayloadAction<string[]>) => {
            let recipients: string[] = []
            for (let item of action.payload) {
                const address = item.toLowerCase()
                if (ethers.utils.isAddress(address) && recipients.indexOf(address) < 0) {
                    recipients.push(address)
                }
            }
            state.recipients.value = recipients
        },
        setRecipientsError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.recipients.error = {status: true, text: action.payload}
            } else {
                state.recipients.error = {status: false, text: ''}
            }
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title.value = action.payload
        },
        setTitleError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.title.error = {status: true, text: action.payload}
            } else {
                state.title.error = {status: false, text: ''}
            }
        },
        setUnlockTime: (state, action: PayloadAction<number | null>) => {
            state.unlockTime.value = action.payload
        },
        setUnlockTimeError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.unlockTime.error = {status: true, text: action.payload}
            } else {
                state.unlockTime.error = {status: false, text: ''}
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadExternalUrl.fulfilled, (state, action: PayloadAction<LoadingExternalUrlType | null>) => {
            if (!action.payload) {
                state.loadedImage = null
                state.imageUrl.value = null
                state.externalUrl = ''
            } else if (action.payload.error) {
                state.loadedImage = null
                state.imageUrl.value = null
            } else if (action.payload.file) {
                state.loadedImage = action.payload.file
                state.imageUrl.value = state.externalUrl || null
            } else {
                state.loadedImage = null
                state.imageUrl.value = null
                state.externalUrl = ''
            }
        })
    },
})

export const getBatch = (state: RootState): InputData<number | null> => state.input.batch
export const getCollaterals = (state: RootState): InputData<ITicketPrice[]> => state.input.collaterals
export const getDateFilter = (state: RootState): Date | null => state.input.dateFilter
export const getDateFilterAsc = (state: RootState): boolean => state.input.dateFilterAsc
export const getDescription = (state: RootState): InputData<string> => state.input.description
export const getExternalUrl = (state: RootState): string => state.input.externalUrl
export const getImageUrl = (state: RootState): InputData<string | null> => state.input.imageUrl
export const getLoadedImage = (state: RootState): File | null => state.input.loadedImage
export const getProperties = (state: RootState): InputData<IProperty[]> => state.input.properties
export const getRecipients = (state: RootState): InputData<string[]> => state.input.recipients
export const getTitle = (state: RootState): InputData<string> => state.input.title
export const getUnlockTime = (state: RootState): InputData<number | null> => state.input.unlockTime

export const {
    addCollateral,
    addRecipient,
    delCollateral,
    delRecipient,
    editCollateral,
    resetImage,
    resetState,
    setBatch,
    setBatchError,
    setCollaterals,
    setCollateralsError,
    setDateFilter,
    setDateFilterAsc,
    setDescription,
    setDescriptionError,
    setExternalUrl,
    setImageUrlError,
    setLoadedImage,
    setProperties,
    setPropertiesError,
    setRecipients,
    setRecipientsError,
    setTitle,
    setTitleError,
    setUnlockTime,
    setUnlockTimeError,
} = inputSlice.actions

export default inputSlice.reducer
