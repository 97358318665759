import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {getOnChainEvents, requestOnChainEvents, setOnChainEvents} from '../../store/onChainEventsSlice'
import {AppDispatch} from '../../store/store'
import {getCurrentNetwork, getWalletAddress, setModalCreateOnChainEvent} from '../../store/appSlice'
import {AlertElement, ButtonElement} from '../elements'
import {LoadingIcon} from '../icons'

const OnChainEventsManagement = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const events = useSelector(getOnChainEvents)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setOnChainEvents(null))
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (!events) {
            dispatch(requestOnChainEvents())
        }
    }, [events])

    const eventsList = useMemo(() => {
        if (events === null) {
            return <LoadingIcon/>
        }

        if (events.length === 0) {
            return <AlertElement centered>{t('status.noEvents')}</AlertElement>
        }

        return events.map((item, index) => <NavLink
                to={`/onchain/events/${Number(currentNetwork)}/${item.contractAddress}`}
                className="row-table__item mb-3 mb-xl-2"
                key={index}
            >
                <div className="row align-items-center gx-2 gx-md-4">
                    <div className="mb-2 col-12">{item.name}</div>
                </div>
            </NavLink>
        )
    }, [events])
    const addEventHandler = () => {
        dispatch(setModalCreateOnChainEvent(true))
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col col-md-auto">
                    <div className="text-primary mb-1">{t('menu.onchain')}</div>
                    <h1>{t('section.myEvents')}</h1>
                </div>
                <div className="col-md-auto me-md-0 ms-md-auto">
                    <ButtonElement
                        small
                        onClick={addEventHandler}
                    >{t('button.createEvent')}</ButtonElement>
                </div>
            </div>
            <div className="row-table">
                {eventsList}
            </div>
        </div>
    </div>
}

export default OnChainEventsManagement
