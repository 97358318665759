import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getSelectedMintCollection, getWalletAddress, setModalCreateOnChainEvent} from '../../store/appSlice'
import {ButtonElement, DateElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {checkNameString} from '../../utils/functions'
import {createOnChainEvent} from '../../store/onChainEventsSlice'
import {CloseIcon} from '../icons'
import {SelectMintCollection} from '../mint'

interface propsType {
    show: boolean
}

const ModalCreateOnChainEvent = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [createCertificateFrom, setCreateCertificateFrom] = useState<Date | null>(new Date())
    const [createCertificateFromError, setCreateCertificateFromError] = useState('')
    const [createCertificateTo, setCreateCertificateTo] = useState<Date | null>(new Date())
    const [createCertificateToError, setCreateCertificateToError] = useState('')
    const [factoryName, setFactoryName] = useState('')
    const [factoryNameError, setFactoryNameError] = useState('')
    const [factorySymbol, setFactorySymbol] = useState('')
    const [factorySymbolError, setFactorySymbolError] = useState('')
    const selectedCollection = useSelector(getSelectedMintCollection)
//    const [ticketContract, setTicketContract] = useState('')
    const [ticketContractError, setTicketContractError] = useState('')
    const [useTicketFrom, setUseTicketFrom] = useState<Date | null>(new Date())
    const [useTicketFromError, setUseTicketFromError] = useState('')
    const [useTicketTo, setUseTicketTo] = useState<Date | null>(new Date())
    const [useTicketToError, setUseTicketToError] = useState('')
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalCreateOnChainEvent(false))
    }
    const createFactory = () => {
        let error = false
        const name = factoryName.trim()
        const symbol = factorySymbol.trim()
        const useFromTimestamp = useTicketFrom ? Math.floor(useTicketFrom.getTime() / 1000) : 0
        const useToTimestamp = useTicketTo ? Math.floor(useTicketTo.getTime() / 1000) : 0
        const createFromTimestamp = createCertificateFrom ? Math.floor(createCertificateFrom.getTime() / 1000) : 0
        const createToTimestamp = createCertificateTo ? Math.floor(createCertificateTo.getTime() / 1000) : 0

        if (name === '') {
            error = true
            setFactoryNameError(t('error.mustEnter', {name: t('form.label.eventTitle')}))
        } else if (!checkNameString(name)) {
            error = true
            setFactoryNameError(`${t('error.wrong', {name: t('form.label.eventTitle')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
        } else {
            setFactoryNameError('')
        }

        if (symbol === '') {
            error = true
            setFactorySymbolError(t('error.mustEnter', {name: t('form.label.eventTicker')}))
        } else if (!/^[a-zA-Z0-9]*$/.test(symbol)) {
            error = true
            setFactorySymbolError(`${t('error.wrong', {name: t('form.label.eventTicker')})} (a-Z, 0-9)`)
        } else {
            setFactorySymbolError('')
        }

        if (!selectedCollection) {
            error = true
            setTicketContractError(t('error.mustEnter', {name: t('form.label.ticketContract')}))
        } else {
            setTicketContractError('')
        }
/*
        if (ticketContract === '') {
            error = true
            setTicketContractError(t('error.mustEnter', {name: t('form.label.ticketContract')}))
        } else if (!ethers.utils.isAddress(ticketContract)) {
            error = true
            setTicketContractError(t('error.wrong', {name: t('form.label.ticketContract')}))
        } else {
            setTicketContractError('')
        }
*/

        if (useFromTimestamp <= 0) {
            error = true
            setUseTicketFromError(t('error.wrong', {name: `${t('form.label.useTickets')} ${t('form.label.from')}`}))
        } else {
            setUseTicketFromError('')
        }
        if (useToTimestamp <= 0) {
            error = true
            setUseTicketToError(t('error.wrong', {name: `${t('form.label.useTickets')} ${t('form.label.to')}`}))
        } else if (useToTimestamp <= useFromTimestamp) {
            error = true
            setUseTicketToError(t('error.mustBeGreater', {
                name: `${t('form.label.useTickets')} ${t('form.label.to')}`,
                value: `${t('form.label.useTickets')} ${t('form.label.from')}`,
            }))
        } else {
            setUseTicketToError('')
        }

        if (createFromTimestamp <= 0) {
            error = true
            setCreateCertificateFromError(t('error.wrong', {name: `${t('form.label.createCertificates')} ${t('form.label.from')}`}))
        } else if (createFromTimestamp <= useToTimestamp) {
            error = true
            setCreateCertificateFromError(t('error.mustBeGreater', {
                name: `${t('form.label.createCertificate')} ${t('form.label.from')}`,
                value: `${t('form.label.useTickets')} ${t('form.label.to')}`,
            }))
        } else {
            setCreateCertificateFromError('')
        }
        if (createToTimestamp <= 0) {
            error = true
            setCreateCertificateToError(t('error.wrong', {name: `${t('form.label.createCertificates')} ${t('form.label.to')}`}))
        } else if (createToTimestamp <= createFromTimestamp) {
            error = true
            setCreateCertificateToError(t('error.mustBeGreater', {
                name: `${t('form.label.createCertificate')} ${t('form.label.to')}`,
                value: `${t('form.label.createCertificate')} ${t('form.label.from')}`,
            }))
        } else {
            setCreateCertificateToError('')
        }

        if (error || !walletAddress || !selectedCollection) {
            return
        }

        dispatch(createOnChainEvent({
            name: factoryName,
            symbol: factorySymbol,
            ticketContract: selectedCollection,
            useFrom: useFromTimestamp,
            useTo: useToTimestamp,
            createFrom: createFromTimestamp,
            createTo: createToTimestamp,
        }))
    }
    const useTicketToHandler = (val: Date | null) => {
        setUseTicketTo(val)
        if (val) {
            setCreateCertificateFrom(new Date(val.getTime() + 1000))
        }
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        id="newEventModal"
        tabIndex={-1}
        aria-labelledby="Create a new event"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.createEvent')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6 col-lg-8">
                            <div className="mb-4">
                                <InputElement
                                    errorText={factoryNameError}
                                    label={<>{t('form.label.eventTitle')} <span className="text-muted">(a-Z, 0-9, "-", ".", "_", "~")</span></>}
                                    value={factoryName}
                                    onChange={(value) => {
                                        if (checkNameString(value)) {
                                            setFactoryName(value)
                                            setFactoryNameError('')
                                        } else {
                                            setFactoryNameError(`${t('error.wrong', {name: t('form.label.eventTitle')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="mb-4">
                                <InputElement
                                    errorText={factorySymbolError}
                                    label={<>{t('form.label.eventTicker')} <span className="text-muted">(a-Z, 0-9)</span></>}
                                    value={factorySymbol}
                                    onChange={(value) => {
                                        if (/^[a-zA-Z0-9]*$/.test(value)) {
                                            setFactorySymbol(value)
                                            setFactorySymbolError('')
                                        } else {
                                            setFactorySymbolError(`${t('error.wrong', {name: t('form.label.eventTicker')})} (a-Z, 0-9)`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="mb-4">
                                <SelectMintCollection
                                    errorText={ticketContractError}
                                    fullWidth={true}
                                />
{/*
                                <InputElement
                                    errorText={ticketContractError}
                                    label={t('form.label.ticketContract')}
                                    value={ticketContract}
                                    isAddress={true}
                                    onChange={setTicketContract}
                                    setError={setTicketContractError}
                                />
*/}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <h4 className="mb-3 text-primary">{t('form.label.useTickets')}</h4>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={useTicketFrom}
                                    onChange={setUseTicketFrom}
                                    errorText={useTicketFromError}
                                    label={t('form.label.from')}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={useTicketTo}
                                    onChange={useTicketToHandler}
                                    errorText={useTicketToError}
                                    label={t('form.label.to')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="mb-3 text-primary">{t('form.label.createCertificate')}</h4>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={createCertificateFrom}
                                    onChange={setCreateCertificateFrom}
                                    errorText={createCertificateFromError}
                                    label={t('form.label.from')}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={createCertificateTo}
                                    onChange={setCreateCertificateTo}
                                    errorText={createCertificateToError}
                                    label={t('form.label.to')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={createFactory}
                    >{t('button.create')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateOnChainEvent
