import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Tippy from '@tippyjs/react'
import {useTranslation} from 'react-i18next'
import {IToken} from '../../store/types'
import {setModalConfirmation} from '../../store/appSlice'
import {AppDispatch} from '../../store/store'
import {applyOnChainTicket, createOnChainCertificate, getCurrentOnChainEvent} from '../../store/onChainEventsSlice'
import {CHAINS} from '../../utils/constants'
import {ThemeContext} from '../../context'
import {CertificateIcon, ExternalLinkSmallIcon, TicketIcon} from '../icons'
import {compactString, getDateString} from '../../utils/functions'
import {ButtonElement, CopyToClipboardBlock} from '../elements'
import {getToken, requestToken} from '../../store/tokensSlice'

interface PropsType {
    item: IToken
}

const OnChainTicketRow = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [opened, setOpened] = useState(false)
    const event = useSelector(getCurrentOnChainEvent)
    const token = useSelector(getToken(item.contract, item.tokenId))
    const networkStr = `0x${Number(item.network).toString(16)}`
    let date = token && token.date ? getDateString(token.date) : item.blockNum.toString()
    const dateNow = Date.now() / 1000

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestToken({address: item.contract, tokenId: item.tokenId, network: networkStr}))
    }, [])

    const createCertificateHandler = () => {
        dispatch(setModalConfirmation({
            title: t('modal.question.createCertificate'),
            confirmAction: () => {
                dispatch(createOnChainCertificate(item.tokenId))
            },
        }))
    }
    const useTicketHandler = () => {
        dispatch(setModalConfirmation({
            title: t('modal.question.useTicket'),
            confirmAction: () => {
                dispatch(applyOnChainTicket(item.tokenId))
            },
        }))
    }

    if (!event) {
        return null
    }

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${Number(item.network).toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${Number(item.network).toString(16)}`]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-auto order-4 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.type')}:</span>
                <Tippy
                    content={isNaN(item.rules) ? t('ticket.type.active') : item.rules === 0 ? t('ticket.type.used') : t('ticket.type.certificate')}
                    appendTo={document.getElementsByClassName("wrapper")[0]}
                    trigger='mouseenter'
                    interactive={false}
                    arrow={false}
                    maxWidth={512}
                >
                        <span
                            className={`icon-${isNaN(item.rules) ? 'ticket-active' : item.rules === 0 ? 'ticket' : 'yellow'}`}>
                            {!isNaN(item.rules) && item.rules !== 0 ? <CertificateIcon/> : <TicketIcon/>}
                        </span>
                </Tippy>
            </div>
            <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-5 col-md order-5 order-md-0">
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                {item.tokenUri ?
                    <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                        JSON <ExternalLinkSmallIcon/>
                    </a>
                    :
                    null
                }
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                <a
                    href={CHAINS[networkStr].blockExplorerNftLink(item.contract, item.tokenId.toString())}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'icon-link'}
                >
                    <span className="text-white">{date}</span>
                    <ExternalLinkSmallIcon/>
                </a>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                {isNaN(item.rules) ?
                    dateNow >= event.useTicket.start && dateNow < event.useTicket.finish ?
                        <ButtonElement
                            small
                            outline
                            onClick={useTicketHandler}
                        >{t('button.use')}</ButtonElement>
                        :
                        dateNow < event.useTicket.start ? t('status.dateNotArrived') : t('status.dateExpired')
                    :
                    item.rules === 0 ?
                        dateNow >= event.certificate.start && dateNow < event.certificate.finish ?
                            <ButtonElement
                                small
                                outline
                                onClick={createCertificateHandler}
                            >{t('button.createCertificate')}</ButtonElement>
                            :
                            dateNow < event.certificate.start ? t('status.dateNotArrived') : t('status.dateExpired')
                        :
                        null
                }
            </div>
        </div>
        <div className="row-table__item-details border-top mt-1 d-md-none" style={{display: opened ? 'block' : 'none'}}>
            <div className="row pt-3 pb-2">
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        {item.tokenUri ?
                            <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'}
                               className={'icon-link'}>JSON</a>
                            :
                            null
                        }
                        <a className="icon-link" href="#">
                            <span className="text-white">12 oct 2023 11:00</span>
                            <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row-table__item-toggle d-md-none">
            <button className="btn btn-sm" onClick={() => setOpened(!opened)}>
                {opened ? t('button.hideDetails') : t('button.showDetails')}
            </button>
        </div>
    </div>
}

export default OnChainTicketRow
