import React, {useContext} from 'react'
import {
    ButtonElement, CheckboxElement, CopyToClipboardBlock,
    DateElement,
    DropdownSelector,
    InputElement,
    TextareaElement
} from '../elements'
import {AddIcon, ExternalLinkSmallIcon, TicketIcon} from '../icons'
import {CHAINS} from '../../utils/constants'
import {ThemeContext} from '../../context'

const WizardLazy = () => {
    const {dark} = useContext(ThemeContext)

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h1>Lazy wizard</h1>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <h2>Page 1: create event</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <InputElement
                            value={''}
                            onChange={() => {
                            }}
                            label={'Event title'}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="mb-4">
                        <DateElement
                            value={new Date()}
                            onChange={() => {
                            }}
                            label={'Start date'}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="mb-4">
                        <DateElement
                            value={new Date()}
                            onChange={() => {
                            }}
                            label={'End date'}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4">
                        <TextareaElement
                            value={''}
                            onChange={() => {
                            }}
                            label={'Event description'}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 text-end">
                        <ButtonElement outline additionalClass={'me-4'}>Skip</ButtonElement>
                        <ButtonElement>Create</ButtonElement>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <h2>Page 2: create showcase</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <InputElement
                            label={<>Showcase name <span
                                className="text-muted">(a-Z, 0-9, "-", ".", "_", "~")</span></>}
                            value={''}
                            onChange={() => {
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <div className="mb-4">
                        <DateElement
                            value={new Date()}
                            onChange={() => {
                            }}
                            label={'Enable after'}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="mb-4">
                        <DateElement
                            value={new Date()}
                            onChange={() => {
                            }}
                            label={'Disable after'}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <DropdownSelector
                            label={'Event (not required)'}
                            list={[{id: 1, name: 'Event title'}]}
                            setItem={() => {
                            }}
                            currentItem={1}
                        />
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-12">
                    <div className="mb-4 text-end">
                        <ButtonElement>Create</ButtonElement>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <h2>Page 3: create tariffs</h2>
                </div>
            </div>
            <div className={'row'}>
                <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <div className="item-card">
                        <div className="px-3 py-3">
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <span>0x3...123</span>
                                    <CopyToClipboardBlock text={'0x3...123'}/>
                                </div>
                                <div>
                                    <span className="text-muted">ID </span><span>0</span>
                                    <CopyToClipboardBlock text={0}/>
                                </div>
                            </div>
                        </div>
                        <div className="item-card__image">
                            <div className="image-container">
                                <img
                                    src={'https://ipfs.io/ipfs/bafybeigp7c2nz3qf264nevawkctswkz76svro6kfziqukjnpryhkijpbvm/photo_2023-09-20_07-49-54.jpg'}
                                    alt={''}/>
                            </div>
                        </div>
                        <div className="px-3 py-3">
                            <div className="mb-3">
                                <DropdownSelector list={[{id: 1, name: '0.01 ETH'}]} currentItem={1} setItem={() => {
                                }}/>
                            </div>
                            <ButtonElement additionalClass={'w-100'}>Edit price</ButtonElement>
                        </div>
                    </div>
                </div>
                <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <div className="item-card">
                        <div className="px-3 py-3">
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <span>0x3...123</span>
                                    <CopyToClipboardBlock text={'0x3...123'}/>
                                </div>
                                <div>
                                    <span className="text-muted">ID </span><span>0</span>
                                    <CopyToClipboardBlock text={0}/>
                                </div>
                            </div>
                        </div>
                        <div className="item-card__image">
                            <div className="image-container">
                                <img
                                    src={'https://ipfs.io/ipfs/bafybeigp7c2nz3qf264nevawkctswkz76svro6kfziqukjnpryhkijpbvm/photo_2023-09-20_07-49-54.jpg'}
                                    alt={''}/>
                            </div>
                        </div>
                        <div className="px-3 py-3">
                            <div className="mb-3">
                                <DropdownSelector list={[{id: 1, name: '0.1 ETH'}]} currentItem={1} setItem={() => {
                                }}/>
                            </div>
                            <ButtonElement additionalClass={'w-100'}>Edit price</ButtonElement>
                        </div>
                    </div>
                </div>
                <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <div className="item-card">
                        <div className="px-3 py-3">
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <span>0x3...123</span>
                                    <CopyToClipboardBlock text={'0x3...123'}/>
                                </div>
                                <div>
                                    <span className="text-muted">ID </span><span>0</span>
                                    <CopyToClipboardBlock text={0}/>
                                </div>
                            </div>
                        </div>
                        <div className="item-card__image">
                            <div className="image-container">
                                <img
                                    src={'https://ipfs.io/ipfs/bafybeigp7c2nz3qf264nevawkctswkz76svro6kfziqukjnpryhkijpbvm/photo_2023-09-20_07-49-54.jpg'}
                                    alt={''}/>
                            </div>
                        </div>
                        <div className="px-3 py-3">
                            <div className="mb-3">
                                <DropdownSelector list={[{id: 1, name: '1.0 ETH'}]} currentItem={1} setItem={() => {
                                }}/>
                            </div>
                            <ButtonElement additionalClass={'w-100'}>Edit price</ButtonElement>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-9 col-md-8 col-lg-6 col-xl-5">
                    <div className="mb-3">
                        <DropdownSelector
                            list={[{id: 1, name: 'Collection name'}]}
                            setItem={() => {
                            }}
                            currentItem={1}
                        />
                    </div>
                </div>
                <div className="col-3 col-md-auto">
                    <ButtonElement
                        link
                        additionalClass="w-100 px-2"
                    >
                        <AddIcon/>
                        <span className="d-none d-md-inline">Add collection</span>
                    </ButtonElement>
                </div>
                <div className="col-3 col-md-auto">
                    <ButtonElement
                        link
                        additionalClass="w-100 px-2"
                    >
                        <AddIcon/>
                        <span className="d-none d-md-inline">Add tariff</span>
                    </ButtonElement>
                </div>
            </div>
            <div className="row-table__item mb-4 mb-xl-2">
                <div className="row align-items-center gx-2 gx-md-4">
                    <div className="mb-2 col-2 col-md-auto order-1 order-md-0">
                        <CheckboxElement
                            checked={false}
                            onChange={() => {
                            }}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                        <span className="d-md-none text-muted me-2">Chain:</span>
                        <img
                            src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x1`]?.iconSvg}`}
                            alt={CHAINS[`0x1`]?.label}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-4 order-md-0 offset-2 offset-md-0">
                        <span className="d-md-none text-muted me-2">Type:</span>
                        <span className={`icon-ticket`}><TicketIcon/></span>
                    </div>
                    <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                        <span>0x3...123</span>
                        <CopyToClipboardBlock text={'item.contract'}/>
                    </div>
                    <div className="mb-2 col-5 col-md col-xl-2 order-5 order-md-0">
                        <span>13</span>
                        <CopyToClipboardBlock text={13}/>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl-2 text-truncate">
                        <a href={`#`}>Event title</a>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl">
                        <span>Free (0)</span>
                    </div>
                    <div className="d-none d-md-block mb-2 col-auto">
                        <a href="#" target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                            Data json <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <ButtonElement
                            small
                            outline
                        >Set price</ButtonElement>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <button className="btn p-2 btn-with-opacity btn-toggle">
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M8.7 11.5a1 1 0 0 1-1.4 0L2.5 6.7a1 1 0 1 1 1.4-1.4L8 9.38l4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"
                                      fill="#EEE"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row-table__item mb-4 mb-xl-2">
                <div className="row align-items-center gx-2 gx-md-4">
                    <div className="mb-2 col-2 col-md-auto order-1 order-md-0">
                        <CheckboxElement
                            checked={false}
                            onChange={() => {
                            }}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                        <span className="d-md-none text-muted me-2">Chain:</span>
                        <img
                            src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x1`]?.iconSvg}`}
                            alt={CHAINS[`0x1`]?.label}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-4 order-md-0 offset-2 offset-md-0">
                        <span className="d-md-none text-muted me-2">Type:</span>
                        <span className={`icon-ticket`}><TicketIcon/></span>
                    </div>
                    <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                        <span>0x3...123</span>
                        <CopyToClipboardBlock text={'item.contract'}/>
                    </div>
                    <div className="mb-2 col-5 col-md col-xl-2 order-5 order-md-0">
                        <span>13</span>
                        <CopyToClipboardBlock text={13}/>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl-2 text-truncate">
                        <a href={`#`}>Event title</a>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl">
                        <span>Free (0)</span>
                    </div>
                    <div className="d-none d-md-block mb-2 col-auto">
                        <a href="#" target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                            Data json <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <ButtonElement
                            small
                            outline
                        >Set price</ButtonElement>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <button className="btn p-2 btn-with-opacity btn-toggle">
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M8.7 11.5a1 1 0 0 1-1.4 0L2.5 6.7a1 1 0 1 1 1.4-1.4L8 9.38l4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"
                                      fill="#EEE"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row-table__item mb-4 mb-xl-2">
                <div className="row align-items-center gx-2 gx-md-4">
                    <div className="mb-2 col-2 col-md-auto order-1 order-md-0">
                        <CheckboxElement
                            checked={false}
                            onChange={() => {
                            }}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                        <span className="d-md-none text-muted me-2">Chain:</span>
                        <img
                            src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x1`]?.iconSvg}`}
                            alt={CHAINS[`0x1`]?.label}
                        />
                    </div>
                    <div className="mb-2 col-5 col-md-auto order-4 order-md-0 offset-2 offset-md-0">
                        <span className="d-md-none text-muted me-2">Type:</span>
                        <span className={`icon-ticket`}><TicketIcon/></span>
                    </div>
                    <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                        <span>0x3...123</span>
                        <CopyToClipboardBlock text={'item.contract'}/>
                    </div>
                    <div className="mb-2 col-5 col-md col-xl-2 order-5 order-md-0">
                        <span>13</span>
                        <CopyToClipboardBlock text={13}/>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl-2 text-truncate">
                        <a href={`#`}>Event title</a>
                    </div>
                    <div className="d-none d-xl-block mb-2 col-xl">
                        <span>Free (0)</span>
                    </div>
                    <div className="d-none d-md-block mb-2 col-auto">
                        <a href="#" target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                            Data json <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <ButtonElement
                            small
                            outline
                        >Set price</ButtonElement>
                    </div>
                    <div className="d-none d-lg-block mb-2 col-auto">
                        <button className="btn p-2 btn-with-opacity btn-toggle">
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M8.7 11.5a1 1 0 0 1-1.4 0L2.5 6.7a1 1 0 1 1 1.4-1.4L8 9.38l4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"
                                      fill="#EEE"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-12">
                    <div className="mb-4 text-end">
                        <ButtonElement>Go to event</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default WizardLazy
