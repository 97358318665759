import React from 'react'
import {useConnectWallet} from '@web3-onboard/react'
import {useTranslation} from 'react-i18next'
import {useMatch} from 'react-router-dom'
import {ButtonElement} from '../elements'
import {GATracker} from '../GATracker'

interface propsType {
    additionalClass?: string
    small?: boolean
}

const ConnectWalletButton = (props: propsType) => {
    const {t} = useTranslation()
    const [{connecting}, connect] = useConnectWallet()
    const urlMatch = useMatch('/gifts')

    const connectWallet = async () => {
        GATracker.sendEventTracker(urlMatch ? 'Gifts' : 'Default', 'ConnectWallet')
        await connect()
    }

    return <ButtonElement
        small={props.small}
        additionalClass={props.additionalClass}
        disabled={connecting}
        onClick={connectWallet}
    >
        {connecting ? `${t('status.connecting')}...` : t('button.connectWallet')}
    </ButtonElement>
}

export default ConnectWalletButton
