import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {ButtonElement, CopyToClipboardBlock} from '../elements'
import {setModalMintSbt} from '../../store/appSlice'
import {getEvent, requestEvent} from '../../store/eventsSlice'
import {compactString, getDateString, tokenIsSbt} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {ITicket} from '../../store/types'
import {ThemeContext} from '../../context'
import {ChevronDownIcon, ExternalLinkSmallIcon, SbtIcon, TicketIcon} from '../icons'
import {TicketDescription} from './index'
import {getToken, requestToken} from '../../store/tokensSlice'

interface PropsType {
    item: ITicket
}

const SbtTicketRow = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [opened, setOpened] = useState(false)
    const event = useSelector(getEvent(item.eventId))
    const token = useSelector(getToken(item.contract, item.tokenId))
    const networkStr = `0x${Number(item.network).toString(16)}`
    let date = token && token.date ? getDateString(token.date) : item.blockNum.toString()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!event && item.isTicket) {
            dispatch(requestEvent(item.eventId))
        }
        dispatch(requestToken({address: item.contract, tokenId: item.tokenId, network: networkStr}))
    }, [])

    const createSbtHandler = () => {
        dispatch(setModalMintSbt({
            tokenId: item.tokenId,
            contract: item.contract,
            assetType: item.assetType
        }))
    }
    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-5 col-md-auto order-1 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${Number(item.network).toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${Number(item.network).toString(16)}`]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-auto order-3 order-md-0">
                <span className="d-md-none text-muted me-2">
                    {item.isTicket ? `${t('form.label.type')}:` : t('form.label.notTicket')}
                </span>
                <span className={`icon-${item.isTicket ? (item.isUsed ? 'ticket' : 'ticket-active') : 'empty'}`}>
                    <TicketIcon/>
                </span>
            </div>
            <div className="mb-2 col-5 col-md order-2 order-md-0 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-5 col-md col-xl-2 order-4 order-md-0">
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="d-none d-xl-block mb-2 col-xl text-truncate">
                {event ? <Link to={`/event/${event.organizerUrl}/${event.url}`}>{event.title}</Link> : null}
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                {item.tokenUri ?
                    <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>JSON</a>
                    :
                    null
                }
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                <a
                    href={CHAINS[networkStr].blockExplorerNftLink(item.contract, item.tokenId.toString())}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'icon-link'}
                >
                    <span className="text-white">{date}</span>
                    <ExternalLinkSmallIcon/>
                </a>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                {!tokenIsSbt(item.rules) ?
                    <ButtonElement
                        small
                        outline
                        additionalClass={'w-120'}
                        onClick={createSbtHandler}
                    >{t('button.createSbt')}</ButtonElement>
                    :
                    <ButtonElement
                        small
                        type={'secondary'}
                        disabled
                        additionalClass={'w-120'}
                    ><SbtIcon/></ButtonElement>
                }
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <button className="btn p-2 btn-with-opacity btn-toggle" onClick={() => {
                    setOpened(!opened)
                }}>
                    <ChevronDownIcon/>
                </button>
            </div>
        </div>
        <div className="row-table__item-details border-top mt-1" style={{display: opened ? 'block' : 'none'}}>
            <TicketDescription item={item} event={event}/>
        </div>
        <div className="row-table__item-toggle d-lg-none">
            <div className="btn-group w-100">
                <ButtonElement
                    small
                    type={'no-primary'}
                    onClick={() => {
                        setOpened(!opened)
                    }}
                >{opened ? t('button.hideDetails') : t('button.showDetails')}</ButtonElement>
                {!tokenIsSbt(item.rules) ?
                    <ButtonElement
                        small
                        onClick={createSbtHandler}
                    >{t('button.createSbt')}</ButtonElement>
                    :
                    'SBT'
                }
            </div>
        </div>
    </div>
}

export default SbtTicketRow
