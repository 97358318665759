import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {ButtonElement, CheckboxElement, CopyToClipboardBlock} from '../elements'
import {getEvent, requestEvent} from '../../store/eventsSlice'
import {compactString} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {ITicket} from '../../store/types'
import {ThemeContext} from '../../context'
import {ExternalLinkSmallIcon, TicketIcon} from '../icons'
import {getSelectedTickets, selectedTicketHandler} from '../../store/launchpadSlice'
import {TicketDescription} from './index'

interface PropsType {
    item: ITicket
}

const LaunchpadTicketRow = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [opened, setOpened] = useState(false)
    const selectedTickets = useSelector(getSelectedTickets)
    const event = useSelector(getEvent(item.eventId))
    let ticketSelected = false
    for (let ticket of selectedTickets) {
        if (ticket.contract === item.contract && ticket.tokenId === item.tokenId) {
            ticketSelected = true
            break
        }
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!event && item.isTicket) {
            dispatch(requestEvent(item.eventId))
        }
    }, [])

    const launchpadTicketHandler = () => {
        dispatch(selectedTicketHandler({assetType: item.assetType, contract: item.contract, tokenId: item.tokenId}))
    }

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-2 col-md-auto order-1 order-md-0">
                <CheckboxElement checked={ticketSelected} onChange={launchpadTicketHandler}/>
            </div>
            <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/static/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${Number(item.network).toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${Number(item.network).toString(16)}`]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-auto order-4 order-md-0 offset-2 offset-md-0">
                <span className="d-md-none text-muted me-2">
                    {item.isTicket ? `${t('form.label.type')}:` : t('form.label.notTicket')}
                </span>
                <span className={`icon-${item.isTicket ? (item.isUsed ? 'ticket' : 'ticket-active') : 'empty'}`}>
                    <TicketIcon/>
                </span>
            </div>
            <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-5 col-md col-xl-2 order-5 order-md-0">
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="d-none d-xl-block mb-2 col-xl-2 text-truncate">
                {event ?
                    <a href={`/event/${event.organizerUrl}/${event.url}`} target={'_blank'}>{event.title}</a>
                    :
                    null
                }
            </div>
            <div className="d-none d-xl-block mb-2 col-xl">
                <span>{item.level ? `${item.level.title} (${item.level.level})` : ''}</span>
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                {item.tokenUri ?
                    <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>JSON</a>
                    :
                    null
                }
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                <a className="icon-link" href="#">
                    <span className="text-white">12 oct 2023 11:00</span>
                    <ExternalLinkSmallIcon/>
                </a>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <button className="btn p-2 btn-with-opacity btn-toggle" onClick={() => {
                    setOpened(!opened)
                }}>
                    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.7 11.5a1 1 0 0 1-1.4 0L2.5 6.7a1 1 0 1 1 1.4-1.4L8 9.38l4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"
                              fill="#EEE"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div className="row-table__item-details border-top mt-1" style={{display: opened ? 'block' : 'none'}}>
            <TicketDescription item={item} event={event}/>
        </div>
        <div className="row-table__item-toggle d-lg-none">
            <div className="btn-group w-100">
                <ButtonElement
                    small
                    type={'no-primary'}
                    onClick={() => {
                        setOpened(!opened)
                    }}
                >{opened ? t('button.hideDetails') : t('button.showDetails')}</ButtonElement>
            </div>
        </div>
    </div>
}

export default LaunchpadTicketRow
