import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getSelectedGiftCollection, getSelectedGiftCollectionError,
    getWalletAddress,
    setModalCreateGiftsCollection, setSelectedGiftCollection, setSelectedGiftCollectionError
} from '../../store/appSlice'
import {setToNetworkStorage} from '../../store/storage'
import {DropdownSelector} from '../elements'
import CopyToClipboardInput from '../elements/CopyToClipboardInput'
import {getGiftsCollections, requestGiftsCollections, setGiftsCollections} from '../../store/giftsSlice'
import {getPublicCollectionDropdown} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {IDropdownItem} from '../../store/types'

const SelectGiftsCollection = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const collections = useSelector(getGiftsCollections)
    const selectedCollection = useSelector(getSelectedGiftCollection)
    const selectedCollectionError = useSelector(getSelectedGiftCollectionError)
    const walletAddress = useSelector(getWalletAddress)
    const publicDropdown = getPublicCollectionDropdown()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (currentNetwork && walletAddress) {
            dispatch(requestGiftsCollections())
        } else if (collections) {
            dispatch(setGiftsCollections(null))
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (!currentNetwork) {
            selectCollectionHandler(publicDropdown.id)
        } else if (collections && selectedCollection !== publicDropdown.id) {
            let found = false
            for (let item of collections) {
                if (selectedCollection === item.contractAddress) {
                    found = true
                    break
                }
            }
            if (!found) {
                selectCollectionHandler(collections[collections.length - 1]?.contractAddress || publicDropdown.id)
            }
        }
    }, [collections])

    let collectionsList: IDropdownItem[] = [publicDropdown]
    collectionsList.push(...(collections?.map((item): IDropdownItem => {
        return {id: item.contractAddress, name: item.name}
    }) || []))

    const createCollectionHandler = () => {
        dispatch(setModalCreateGiftsCollection(true))
    }
    const selectCollectionHandler = (address: string) => {
        dispatch(setSelectedGiftCollection(address))
        setToNetworkStorage('selectedGiftCollection', address, currentNetwork)
        if (selectedCollectionError) {
            dispatch(setSelectedGiftCollectionError(null))
        }
    }

    return <>
        <div className="row">
            <div className="col-12 col-md-8 col-xl-6">
                <div className="mb-3">
                    <DropdownSelector
                        list={collectionsList}
                        setItem={selectCollectionHandler}
                        currentItem={selectedCollection}
                        label={t('form.label.collection')}
                        firstItem={{id: 0, name: t('button.createCollection')}}
                        firstItemAction={createCollectionHandler}
                        errorText={selectedCollectionError || undefined}
                    />
                </div>
            </div>
        </div>
        <div className="row mb-4 mb-xl-6">
            {collections && selectedCollection ?
                <div className="col-md-8 col-xl-6">
                    <div className="input-group mb-3">
                        <CopyToClipboardInput text={selectedCollection === publicDropdown.id ?
                            currentNetwork ? CHAINS[currentNetwork].wnftPublic721 : t('button.connectWallet')
                            :
                            selectedCollection
                        }/>
                    </div>
                </div>
                :
                null
            }
        </div>
    </>
}

export default SelectGiftsCollection
